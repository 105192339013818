<template>
  <div
    class="layout-topbar px-4 flex align-items-center justify-content-between">
    <Button
      id="btn-menu"
      class="p-button-outline p-button-text p-button-lg p-button-rounded"
      icon="pi pi-bars"
      @click="onMenuToggle" />
    <div class="usuario flex align-items-center">
      <span class="layout-topbar-user-name mr-2">
        {{ $auth.user.nomecompleto }}
      </span>
      <Button
        id="btn-logout"
        class="p-button-outline p-button-text p-button-lg p-button-rounded"
        icon="pi pi-user"
        @click="toggleMenu" />
    </div>
    <Menu
      ref="profilemenu"
      class="profile-menu-item-custom"
      :model="items"
      :popup="true" />
  </div>
</template>

<script>
import Config from '@/config'

export default {
  data() {
    return {
      items: [],
    }
  },

  created() {
    this.preencheItens()
  },

  methods: {
    preencheItens() {
      const itemsBase = [
        {
          label: this.$auth.user.nomecompleto,
          disabled: true,
          class: 'menu-item-responsive',
        },
        {
          separator: true,
          class: 'menu-item-responsive',
        },
        {
          label: 'Sair',
          icon: 'pi pi-sign-out',
          command: () => {
            this.$router.push('/logout')
          },
        },
      ]
      if (Config.HABILITA_BOTAO_2FA) {
        const item = {
          label: 'Autenticação de dois fatores',
          command: () => {
            window.location.href =
              Config.KEYCLOAK_URL +
              '/realms/' +
              Config.KEYCLOAK_REALM +
              '/protocol/openid-connect/auth?client_id=' +
              Config.KEYCLOAK_CLIENT_ID +
              '&redirect_uri=' +
              window.location.origin +
              window.location.pathname +
              '&response_type=code&scope=openid&kc_action=CONFIGURE_TOTP'
          },
        }
        itemsBase.splice(1, 0, item)
      }
      this.items = itemsBase
    },

    toggleMenu(event) {
      this.$refs.profilemenu.toggle(event)
    },
    onMenuToggle(event) {
      this.$emit('menu-toggle', event)
    },
  },
}
</script>
<style>
.profile-menu-item-custom span {
  padding-left: 5px;
  padding-right: 5px;
}

@media print {
  .layout-topbar {
    display: none;
  }
}
</style>
